import 'antd/dist/antd.min.css';
import "./App.scss";
import Footer from "./component/footer";
import Header from "./component/header";


function App() {
  return (
    <div className="app">
      <header className="app_header">
        <Header />
      </header>
      <main className="app_main">
        <div className="app_main_img-box">
          <img src="/ip_tz.png" alt="ip_tz" />
        </div>
        <div className="app_main_des-box">风吹哪页读哪页</div>
        <div className="app_main_link-box">
          <a target="_blank" href="https://www.zhihu.com/people/90-7-33-68/posts" rel="noreferrer">知乎</a>
          <a target="_blank" href="https://gitee.com/hiphopCode-ctz" rel="noreferrer">
            gitee
          </a>
          <a target="_blank" href="https://www.xiaohongshu.com/user/profile/5f5b6f970000000001002cc3" rel="noreferrer">
            小红书
          </a>
        </div>
      </main>
      <footer className="app_footer">
        <Footer />
      </footer>
    </div>
  );
}

export default App;
