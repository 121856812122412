import React, { useEffect, useState } from "react";
import "./index.scss";

interface HeaderNavType {
  key: string;
  label: string;
}

const list = [
  {
    key: "blog",
    label: "博客",
  },
  {
    key: "record",
    label: "日常",
  },
  {
    key: "history",
    label: "更新记录",
  },
  {
    key: "comments",
    label: "留言",
  },
];

export default function Header() {
  const [navList, setNavList] = useState<HeaderNavType[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [load, setLoad] = useState(false);

  useEffect(() => {
    // 初始化list
    setLoad(true);
    setTimeout(() => {
      setNavList(list);
      setLoad(false);
    }, 1000);
  }, []);

  return (
    <div className="header_container">
      <div className="header_logo">天泽的博客</div>
      <div className="header_nav">
        {navList.map((navObj) => (
          <div
            key={navObj.key}
            className="header_nav_item"
            onClick={() => alert("正在开发...")}
          >
            {navObj.label}
          </div>
        ))}
      </div>
    </div>
  );
}
