import React from "react";
import "./index.scss";

export default function Footer() {
  return (
    <div className="footer_container">
      <span className="link_item">
        <div className="beianImg">
          <img src="/beian.png" alt="备案图标" />
        </div>
        <a
          href="https://beian.mps.gov.cn/#/query/webSearch?code=43012402000873"
          rel="noreferrer"
          target="_blank"
        >
          湘公网安备43012402000873
        </a>
      </span>
      <span className="link_item">
        <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
          湘ICP备2024073575号
        </a>
      </span>
      <span className="link_item">&copy;tz </span>
    </div>
  );
}
