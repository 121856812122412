import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Show: FC = () => {
  const { id } = useParams();
  const [path, setPath] = useState("");

  useEffect(() => {
    setPath(`${process.env.PUBLIC_URL}/demo/${id}/index.html`);
  }, [id]);

  return (
    <iframe
      title={id}
      src={path}
      width="100%"
      height="100%"
      style={{ border: "none" }} // 去掉边框
    />
  );
};

export default Show;
